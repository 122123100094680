export function userContactsChangesTableHead () {
  return [
    {
      text: 'Id',
      align: 'start',
      value: 'id'
    },
    {
      text: 'Field',
      value: 'field'
    },
    {
      text: 'Value',
      value: 'value'
    },
    {
      text: 'Comment',
      value: 'adminMessage'
    },
    {
      text: 'Actions',
      value: 'actions'
    }
  ]
}
